import APIHandler from '@/lib/api-handler';

export default {

	namespaced : true ,
	state : {
		invites : [] ,
		error : false ,
		loading : false
	},

	mutations : {
		setInvites( state , invites ){ 
			state.invites = invites;
		},
		acceptInvite( state , inviteid ){
			let idx = state.invites.findIndex( i => i._id == inviteid );
			if( idx >= 0 ) state.invites.splice( idx , 1 );
		},
		declineInvite( state , inviteid ){
			let idx = state.invites.findIndex( i => i._id == inviteid );
			if( idx >= 0 ) state.invites.splice( idx , 1 );
		}
	},

	actions : {

		async load({ commit }){
			let response = await APIHandler.call( "GET" , "/api/invites" );
			if( response.success ){
				commit( 'setInvites' , response.invites );
			}
		},

		accept({ commit } , inviteForm ){
			return new Promise( ( res , rej ) => {
				APIHandler.call( "POST" , "/api/invites/:id/accept" , { id : inviteForm.inviteId } , { 
					code : inviteForm.inviteCode || null ,
					invitetoken : inviteForm.invitetoken || null 
				})
				.then( response => {
					if( response.success ){
						commit( 'acceptInvite' , inviteForm.inviteId );
					}
					res( response );
				} , error => {
					rej( error );
				});
			});
		},

		decline({ commit } , inviteid ){
			return new Promise( ( res , rej ) => {
				APIHandler.call( "DELETE" , "/api/invites/:id" , { id : inviteid } )
				.then( response => {
					if( response.success ){
						commit( 'declineInvite' , inviteid );
					}
					res( response );
				} , error => {
					rej( error );
				});
			});
		}
	}

}