const axios = require('axios');
const moment = require('moment-timezone');

// Overrides for different environments
let baseURL = process.env.VUE_APP_API_URL;
const ajax = axios.create({ baseURL: baseURL });

class ServiceHandler {
    
    call( method , url , data = {} , options = {} ){

        var nocache = options.nocache === false ? false : true; // Disable cache by default
        if( nocache && !data._ts ){
            data._ts = moment().valueOf(); // Append a timestamp to all outgoing requests where nocache = true
        }

        // Basic configuration for an http request
        var httpConfig = {
            method: method,
            url: url,
            data: data,
            params: data
        };

        // Any special request transformations from the calling function?
        if( options.transformRequest ){
            httpConfig.transformRequest = options.transformRequest;
        }
        // Any special headers from the calling function?
        httpConfig.headers = {};
        if( options.headers ){
            httpConfig.headers = options.headers;
        }
        httpConfig.headers.referrer = window.location.origin;
		
		if( method == 'POST' || method == 'PUT' || ( method == 'DELETE' && options.sendDeleteAsBody ) ){
			httpConfig.headers['Content-Type'] = 'application/json';
			delete httpConfig.params;
		}

		let handler = ajax;
		if( options.baseURL ){
			handler = axios;
			httpConfig.url = options.baseURL + httpConfig.url;
		}

        return handler.request( httpConfig )
        .then( function(response){
            return response.data;
        });   
	}
	
}

module.exports = ServiceHandler;