import APIHandler from '@/lib/api-handler';
import triggers from '@/components/data/triggers.js';

export default {
	namespaced : true ,
	state : {
		factors : [ ...triggers ] ,
		disabled : []
	},
	mutations : {
		setFactors( state , factors ){
			state.factors.splice(0);
			state.factors.push( ...factors );
		},
		setDisabled( state , disabled ){
			state.disabled.splice(0);
			state.disabled.push( ...disabled );
		},
		enableFactor( state , factor ){
			let idx = state.disabled.findIndex( d => d.value == factor.value );
			if( idx >= 0 ){
				let enabled = state.disabled.splice( idx , 1 );
				state.factors.push( enabled[0] );
			}
		}
	},
	actions : {
		load: async function({ commit }){
			let response = await APIHandler.call( "GET" , "/api/organization/factors" );
			if( response.success ){
				commit( 'setFactors' , response.factors );
				commit( 'setDisabled' , response.disabled );
			}
		},
		save: async function({commit} , factors ){

			let response = await APIHandler.call( "PUT" , "/api/organization/factors" , {} , {factors} );
			if( response.success ){
				commit( 'setFactors' , response.factors );
				commit( 'setDisabled' , response.disabled );
			}	

			return response;
			
		},
		reset: async function({commit}){

			let response = await APIHandler.call( "DELETE" , "/api/organization/factors" );
			if( response.success ){
				commit( 'setFactors' , triggers );
				commit( 'setDisabled' , response.disabled );
			}	

			return response;
			
		}
	}
}