import APIHandler from '@/lib/api-handler';

export default {

	namespaced : true ,
	state : {
		organization : {} ,
		members : [] ,
	},

	mutations : {
		setOrganization( state , organization ){
			state.organization = organization;
		},
		setMembers( state , members ){
			state.members = members;
		},
		removeMember( state , userid ){
			let idx = state.members.findIndex( m => m._id == userid );
			if( idx >= 0 ) state.members.splice( idx , 1 );
		},
		setTrustedAdult( state , opts ) {
			let { userid , isTrustedAdult } = opts;
			let idx = state.members.findIndex( m => m._id == userid );

			if( idx >= 0 ){
				state.members[ idx ].isTrustedAdult = isTrustedAdult;
			}
		},
		setBillingStatus( state , status ){
			state.organization.billingStatus = status;
		},
		updateName( state , name ){
			state.organization.name = name;
		},
		updateSetting( state , { key , value } ){
			state.organization.settings ??= {};
			state.organization.settings[ key ] = value;
		}
	},

	actions : {
		reload: async function({ commit }){
			let responseOrg = await APIHandler.call( "GET" , "/api/organization" );
			commit( 'setOrganization' , responseOrg.organization );
			let responseMem = await APIHandler.call( "GET" , "/api/organization/members" );
			commit( 'setMembers' , responseMem.members );
		},
		load: async function({ commit , state } , organizationid ){
			if( state.organization?.id == organizationid ) return true;
			let response = await APIHandler.call( "GET" , "/api/organization" );
			commit( 'setOrganization' , response.organization );
		},
		loadMembers: async function({ commit , state } , organizationid ){
			if( state.organization?.id == organizationid && state.members.length ) return true;
			let response = await APIHandler.call( "GET" , "/api/organization/members" );
			commit( 'setMembers' , response.members );
		},
		removeMember({ commit } , userid ){
			return new Promise( ( res , rej ) => {
				APIHandler.call( "DELETE" , '/api/organization/members/:userid' , { userid } )
				.then( response => {
					if( response.success ){
						commit( 'removeMember' , userid );
					}
					res( response );
				} , error => {
					rej( error );
				});
			});
		},
		setTrustedAdult({ commit } , opts ){
			return new Promise( ( res , rej ) => {
				let { userid , isTrustedAdult } = opts;
				APIHandler.call( "PUT" , '/api/organization/members/:userid/trustedAdult' , { userid } , { isTrustedAdult } )
				.then( response => {
					if( response.success ){
						commit( 'setTrustedAdult' , opts );
					}
					res( response );
				} , error => {
					rej( error );
				});
			});
		}
	
	},

	getters : {
		settings : ( state ) => state.organization.settings
	}

}