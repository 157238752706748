import APIHandler from '@/lib/api-handler';

export default {

	namespaced : true ,
	state : {
		classes : [] ,
		error : false ,
		loading : false
	},

	mutations : {
		
		setError( state , e ){
			state.error = e;
		},

		clearError( state ){
			state.error = null;
		},
	
		setClasses( state , classes ){
			state.classes = classes;
		},

		setLoading( state , loading ){
			state.loading = loading;
		},

		archive( state , classid ){
			// Archive one class
			if( classid && !Array.isArray( classid ) ){
				let idx = state.classes.findIndex( cls => cls.classid == classid );
				if( idx >= 0 ) state.classes.splice( idx , 1 );
			} else if ( classid && Array.isArray( classid )) {

				for( let id of classid ){
					let idx = state.classes.findIndex( cls => cls.classid == id );
					if( idx >= 0 ) state.classes.splice( idx , 1 );
				}

			// Archive all classes
			} else {
				state.classes.splice(0);
			}
		},

		tagClass( state , { classid , tag }){
			let idx = state.classes.findIndex( cls => cls.classid == classid );
			if( idx >= 0 ){
				state.classes[ idx ].tags.push( tag._id );
			}
		},

		unTagClass( state , { classid , tag }){
			let idx = state.classes.findIndex( cls => cls.classid == classid );
			if( idx >= 0 ) {
				let tagidx = state.classes[idx].tags.indexOf( tag._id );
				if( tagidx >= 0 ) {
					state.classes[ idx ].tags.splice( tagidx , 1 );
				}
			}
		},

		sortClasses( state ){
			state.classes.sort( ( a , b ) => a.name > b.name ? 1 : -1 );
		},

		addClass( state , cls ){
			state.classes.push( cls );
			state.classes.sort( ( a , b ) => a.name > b.name ? 1 : -1 );
		},

		updateClass( state , cls ){
			let update = state.classes.findIndex( c => c.classid == cls.classid );
			if( update > -1 ){
				Object.assign( state.classes[ update ] , { name : cls.name , description : cls.description } );
			}
		}


	},

	actions : {
		load: async function({ commit , state } , force = false ) {

			commit( "clearError" );

			// Attempt to load unless there are classes already and we're not forcing a reload
			if( state.classes.length && !force ) return true;

			commit( "setLoading" , true );

			// Load the classes from the back end
			try {

				let response = await APIHandler.call( "GET" , "/api/teacher/classes" );

				if( !response.success ){
					throw new Error();
				}

				commit( "setClasses" , response.classes );
				commit( "sortClasses" );

			} catch( e ) {
				commit( "setError" , "Could not load classes." );
			}

			commit( "setLoading" , false );

			return true;
		},
	},

	getters : {
		className: state => ( id ) => {
			return state.classes?.find( cls => cls.classid == id )?.name;
		}
	}
}