import "bootstrap/dist/css/bootstrap.min.css"

import { createApp } from 'vue'
import App from './App.vue'

import router from './router';
import store from './store';


import VueGtag from "vue-gtag";
import { event , pageview } from 'vue-gtag'

router.beforeEach(( to ) => {
	pageview({ page_title : to.name || 'Unknown' , page_path : to.path });
	store.commit( "ui/closeMenu" );
});

import PerfectScrollbar from 'vue3-perfect-scrollbar'
import 'vue3-perfect-scrollbar/dist/vue3-perfect-scrollbar.css'

import '@vuepic/vue-datepicker/dist/main.css';

import '@kouts/vue-modal/dist/vue-modal.css'

import 'vue-css-donut-chart/dist/vcdonut.css';

import 'vue3-simple-typeahead/dist/vue3-simple-typeahead.css'; //Optional default CSS

import 'shepherd.js/dist/css/shepherd.css';

import 'vue3-toastify/dist/index.css';

import 'floating-vue/dist/style.css'

import Vue3Toastify from 'vue3-toastify';

import mitt from 'mitt';
const emitter = mitt();

const app = createApp(App);
const gtag_id = process.env.VUE_APP_GTAG;
app.use(router)
	.use(store)
	.use(PerfectScrollbar)
	.use(Vue3Toastify , {
		autoClose : 1500 , theme : 'colored'
	})
	.use( VueGtag, {
		config: { id: gtag_id }
	})
	.provide('emitter',emitter)
	.provide('gtag',event)
	.mount('#app')

import "bootstrap/dist/js/bootstrap.js"

import { library } from '@fortawesome/fontawesome-svg-core'
import { faArrowUpWideShort , faCalendar , faStar , faPowerOff , faUser , faUsers , faHouse , faCircleXmark , faCircleCheck , faDatabase , faCommentDots , faGraduationCap , faSchool , faCircleExclamation , faBars , faNoteSticky , faCircleInfo , faQuoteLeft , faEye , faTimes, faMagnifyingGlass , faArrowRight, faList, faRightFromBracket, faUpRightFromSquare, faCalendarDays, faCalendarWeek, faAngleRight, faEllipsis, faMessage, faQuestionCircle, faGear, faChartColumn, faLock, faBell , faFlag, faTemperature2, faTrash, faEnvelope, faTriangleExclamation, faPeopleArrows, faFilePdf, faPlus, faHand, faCircleH, faPencil, faTag, faUndo, faSquareCheck, faQuestion, faThumbtack, faLightbulb, faClock, faCloudArrowUp } from '@fortawesome/free-solid-svg-icons'
import { faFaceAngry, faFaceLaughBeam, faKeyboard, faSadTear, faSurprise } from '@fortawesome/free-regular-svg-icons';

import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
library.add(faStar);
library.add(faPowerOff);
library.add(faUser);
library.add(faUsers);
library.add(faHouse);
library.add(faCircleXmark);
library.add(faCircleCheck);
library.add(faDatabase);
library.add(faCommentDots);
library.add(faGraduationCap);
library.add(faSchool);
library.add(faCircleExclamation);
library.add(faBars);
library.add(faNoteSticky);
library.add(faCircleInfo);
library.add(faQuoteLeft);
library.add(faEye);
library.add(faTimes);
library.add(faKeyboard);
library.add(faArrowUpWideShort);
library.add(faCalendar);
library.add(faMagnifyingGlass);
library.add(faArrowRight);
library.add(faList);
library.add(faRightFromBracket);
library.add(faUpRightFromSquare);
library.add(faCalendarDays);
library.add(faCalendarWeek);
library.add(faAngleRight);
library.add(faEllipsis);
library.add(faMessage);
library.add(faSchool);
library.add(faQuestionCircle);
library.add(faGear);
library.add(faChartColumn);
library.add(faLock);
library.add(faBell);
library.add(faCircleXmark);
library.add(faFlag);
library.add(faTemperature2);
library.add(faTrash);
library.add(faEnvelope);
library.add(faTriangleExclamation);
library.add(faPeopleArrows);
library.add(faFilePdf);
library.add(faPlus);
library.add(faHand);
library.add(faCircleH);
library.add(faPencil);
library.add(faTag);
library.add(faUndo);
library.add(faSquareCheck);
library.add(faQuestion);
library.add(faThumbtack);
library.add(faLightbulb);
library.add(faClock);
library.add(faFaceAngry);
library.add(faFaceLaughBeam);
library.add(faSurprise);
library.add(faSadTear);
library.add(faCloudArrowUp);
app.component('font-awesome-icon', FontAwesomeIcon);

import PulseLoader from 'vue-spinner/src/PulseLoader.vue'
app.component('pulse-loader' , PulseLoader);
import UserAvatar from '@/components/display/UserAvatar.vue';
app.component('user-avatar' , UserAvatar);
import PageHeader from '@/components/layout/PageHeader.vue'
app.component('page-header' , PageHeader);
import ContextMenu from '@/components/layout/ContextMenu.vue'
app.component('context-menu' , ContextMenu);

import handleRefreshToken from "./lib/plugins/handle-refresh-token";
import APIHandler from '@/lib/api-handler';
APIHandler.registerPlugin( handleRefreshToken );