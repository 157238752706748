import APIHandler from '@/lib/api-handler';

const rolesInOrder = [ 'member' , 'readall' , 'admin' , 'owner' ];

export default {

	namespaced : true ,

	state : {
		userid : null ,
		error : false ,
		data : {} ,
		role : '' ,
		loading : false ,
		organization : false ,
		tags : []
	} ,

	mutations : {

		setError( state , e ){
			state.error = e;
		},

		clearError( state ){
			state.error = null;
		},

		setUser( state , user ){
			state.userid = user.userid;
			state.role = user.data.preferredRole;
			state.tags = user.tags;
			delete user.data.preferredRole;
			state.data = user.data;
		},

		setRole( state , role ){
			state.role = role;
		},

		setLoading( state , loading ){
			state.loading = loading;
		},

		setOrganization( state , org ){
			state.organization = org;
		},

		setOrganizationBillingStatus( state , status ){
			if( !state.organization ) return;
			state.organization.billingStatus = status;
		},

		setOrganizationName( state , name ){
			if( !state.organization ) return;
			state.organization.name = name;
		},

		addTag( state , tag ){
			state.tags.push( tag );
		},

		renameTag( state , tag ){
			let findIdx = state.tags.findIndex( t => t._id == tag._id );
			if( findIdx >= 0 ){
				state.tags[ findIdx ].text = tag.text;
			}
		},

		removeTag( state , tag ){
			let findIdx = state.tags.findIndex( t => t._id == tag._id );
			if( findIdx >= 0 ){
				state.tags.splice( findIdx , 1 );
			}
		},

		verifyEmail( state ){
			state.data.mustverify = false;
		},

		setData( state , payload ){
			Object.assign( state.data , payload );
		}

	},

	getters : {

		userid : ( state ) => state.userid ,
		error : ( state ) => state.error ,
		role : ( state ) => state.role ,
		name : ( state ) => state.data.fullName ,
		
		organizationRoleAtLeast: ( state ) => ( role ) => {
			let myRoleIdx = rolesInOrder.indexOf( state.organization.role );
			let checkRoleIdx = rolesInOrder.indexOf( role );
			return myRoleIdx >= checkRoleIdx;
		},

		me : ( state ) => state.data ,
		
		isActiveOrg : ( state ) => state.organization && state.organization.billingStatus != 'Suspended' ,
		tags : ( state ) => state.tags ,
		adminTags : ( state ) => state.tags.filter( t => t.admin )
	},

	actions : {
		load: async function({ commit , state } , reload = false ) {

			commit( "clearError" );

			// Do we already have a user?
			if( state.userid && !reload ) return true;

			// Load the user from the back end
			commit( "setLoading" , true );

			try {
				let response = await APIHandler.call( "GET" , "/api/users/me" );
				if( !response.success ){
					throw new Error();
				}

				commit( "setUser" , response.user );

				if( response.organization ){
					commit( "setOrganization" , response.organization );
				} else {
					commit( "setOrganization" , false );
				}


			} catch( e ) {
				commit( "setError" , "Could not load user." );
			}

			commit( "setLoading" , false );

			return true;
		},

		setRole: async function({ commit } , role ){

			commit( "clearError" );

			// Load the user from the back end
			try {
				
				let response = await APIHandler.call( "POST" , "/api/users/me/role" , {} , { role });
				if( !response.success ){
					throw new Error();
				}

				commit( "setRole" , role );

			} catch( e ) {
				commit( "setError" , "Could update user role." );
			}

		},

		isValidTag : ({ state }, tagid ) => state.tags.some( t => t._id == tagid )


	}


}