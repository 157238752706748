import moment from "moment-timezone";

export default {

	namespaced : true ,
	state : {
		org_suspended : window.localStorage.getItem( 'alert_org_suspended' ) || false
	},
	mutations : {
		dismiss_org_suspended( state ){
			let dis = moment().add( 5 , 'days' );
			window.localStorage.setItem( 'alert_org_suspended' , dis );
			state.org_suspended = window.localStorage.getItem( 'alert_org_suspended' );
		}
	},
	getters : {
		org_suspended_is_dismissed( state ){
			let dis = state.org_suspended;
			if( !dis ) return false;
			if( moment().isAfter( moment( dis ) ) ) return false;
			return true;
		}
	}
}