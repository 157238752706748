const ServiceHandler = require('@/lib/service-handler.js');

class APIHandler {
    
    constructor() {
        this.ServiceHandler = new ServiceHandler();
		this.onResponse = [];
    }

	registerPlugin( module ){
		if( module.onResponse ) this.onResponse.push( module.onResponse );
	}
    
    call( method , url , params = {}, data = {} , options = {} ){
        
        // Parse the url params into the url
        for (var p in params) {
            url = url.replace(':' + p, params[p]);
		}
		
		let token = localStorage.getItem("token");
		if( token && token != "undefined" ) data.token = token;
        
        return this.ServiceHandler.call( method , url , data , options )
		.then( response => {
			this.onResponse.forEach( fn => fn( response ) );
			return response;
		});
        
    }

    buildURL(path, formData){

        var params = "";
        var url = "";

        for (var key in formData) {

            if (Object.prototype.hasOwnProperty.call(formData,key)) {
                params = params + key + '=' + formData[key] + '&';
            }
        }
        
        if(params != ""){
            url = path + '?' + params;
        } else {
            url = path;
        }
        
        return url;
    }
    
}

module.exports = new APIHandler();