import { createStore } from 'vuex';

import user from '@/store/user';
import classes from '@/store/classes';
import ui from '@/store/ui';
import preferences from '@/store/preferences';
import organization from '@/store/organization';
import invites from '@/store/invites';
import alerts from '@/store/alerts';
import factors from '@/store/factors';

export default createStore({
	modules : {
		user , classes , ui , preferences , organization , invites , alerts , factors
	}
});