module.exports = [
	{ value : "trg-teacher" , label : "Teacher(s)" , levels : [ "level-1" ] } ,
	{ value : "trg-friends" , label : "Friends" , levels : [ "level-1" , "level-0" ] } ,
	{ value : "trg-classmates" , label : "Classmates" , levels : [ "level-1" ] } ,
	{ value : "trg-sports" , label : "Sports" , levels : [ "level-1" ] } ,
	{ value : "trg-family" , label : "Family" , levels : [ "level-1" , "level-0" ] } ,
	{ value : "trg-sleep" , label : "Lack of Sleep" , levels : [ "level-1" ] } ,
	{ value : "trg-overwhelmed" , label : "Too much going on" , levels : [ "level-1" ] } ,
	{ value : "trg-activities" , label : "Extracurriculars" , levels : [ "level-1" ] } ,
	{ value : "trg-workload" , label : "Workload" , levels : [ "level-1" ] } ,
	{ value : "trg-mental" , label : "Mental health" , levels : [ "level-1" ] } ,
	{ value : "trg-physical" , label : "Physical health" , levels : [ "level-1" ] } ,
	{ value : "trg-school" , label : "School" , levels : [ "level-0" ] } ,
	{ value : "trg-other" , label : "Other" , levels : [ "level-1" , "level-0" ] }
];