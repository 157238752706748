import { createRouter, createWebHistory } from 'vue-router';

const cmp = (view) => {
    return () => import(`@/views/${view}.vue`);
}

const routes = [
	{
		path : "/" ,
		name: "home" ,
		component: cmp('Home')
	},
	// local student login
	{
		path : "/student/:code" ,
		name: "studentlocal" ,
		component: cmp('StudentLocalLogin')
	},
	{
		path : "/demo" ,
		name: "demo" ,
		component: cmp('Demo')
	},
	{
		path : "/demo-org" ,
		name: "demo-org" ,
		component: cmp('Demo')
	},
	{
		path : "/about" ,
		name: "about" ,
		component: cmp('About')
	},
	{
		path : "/faq" ,
		name: "faq" ,
		component: cmp('FAQ')
	},
	{
		path : "/about/subscription" ,
		name: "organizations" ,
		component: cmp('AboutSubscription')
	},
	{
		path : "/privacy" ,
		name: "privacy" ,
		component: cmp('Privacy')
	},
	{
		path : "/saas-terms" ,
		name: "saas-terms" ,
		component: cmp('SAASTermsAndConditions')
	},
	{
		path : "/login" ,
		name: "login" ,
		component: cmp('Login')
	},
	{
		path : "/createaccount" ,
		name: "createaccount" ,
		component: cmp('CreateAccount')
	},
	{
		path : "/verifyemail/:token" ,
		name: "verifyemail" ,
		component: cmp('VerifyEmail')
	},
	// Users who are logged in go here
	{
		path : "/portal" ,
		name: "portal" ,
		component : cmp('portal/Portal') ,
		children : [
			// Teacher portal
			{ 
				path : "teacher" ,
				component :  cmp('portal/teacher/Main') ,
				children : [
					// Dashboard
					{
						path : "" ,
						redirect : "/portal/teacher/surveys"
					},
					{
						path : "surveys" ,
						component : cmp('portal/teacher/surveys/SurveyController') ,
						name : "surveys-main" ,
						children : [
							{
								name : "surveys-daily" ,
								path : "daily" ,
								component : cmp('portal/teacher/surveys/Surveys')
							},
							{
								name : "surveys-weekly" ,
								path : "weekly" ,
								component : cmp('portal/teacher/surveys/Surveys')
							},
						]
					},
					{
						name : "Alerts" ,
						path : "alerts" ,
						component : cmp('portal/teacher/Alerts')
					},
					{
						name : "Feed" ,
						path : "feed" ,
						component : cmp('portal/teacher/Feed')
					},
					{
						name : "students" ,
						path : "students" ,
						component : cmp('portal/teacher/students/Students')
					},
					{
						name : "upload-students" ,
						path : "students/upload" ,
						component : cmp('portal/teacher/classes/UploadStudents')
					},
					{
						path : "students/:studentid" ,
						component : cmp('portal/teacher/students/StudentView') ,
						name : 'student-main' ,
						children : [
							{
								path : "detail" ,
								name : "student-detail" ,
								component : cmp('portal/teacher/students/StudentDetail') ,
							},
							{
								path : "surveys" ,
								name : "student-surveys" ,
								props : true ,
								component : cmp('portal/teacher/students/StudentSurveys')
							},
							{
								path : "notes" ,
								name : "student-notes" ,
								props : true ,
								component : cmp('portal/teacher/students/StudentNotes')
							},
							{
								path : "questions" ,
								name : "student-questions" ,
								component : cmp('portal/teacher/students/StudentQuestions') ,
							},
						]
					},
					{
						name : "classes" ,
						path : "classes" ,
						component : cmp('portal/teacher/Classes')
					},
					{
						name : "class-detail" ,
						path : "classes/:classid" ,
						component : cmp('portal/teacher/classes/ClassView')
					},
					{
						name : "class-add-students" ,
						path : "classes/:classid/add-students" ,
						component : cmp('portal/teacher/students/Students')
					},
					{
						name : "class-upload-students" ,
						path : "classes/:classid/upload-students" ,
						component : cmp('portal/teacher/classes/UploadStudents')
					},
					{
						name : "questions-index" ,
						path : "questions" ,
						component : cmp('portal/teacher/questions/Questions')
					},
					{
						name : "questions-detail" ,
						path : "questions/:questionid" ,
						component : cmp('portal/teacher/questions/QuestionsDetail') ,
						redirect : "/portal/teacher/questions/profile" ,
						children : [
							{
								name : "questions-detail-responses" ,
								path : "responses" ,
								component : cmp('portal/teacher/questions/QuestionsResponses')
							},
							{
								name : "questions-detail-edit" ,
								path : "edit" ,
								component : cmp('portal/teacher/questions/QuestionsNew')
							}
						]
					},
					{
						name : "questions-new" ,
						path : "questions/new" ,
						component : cmp('portal/teacher/questions/QuestionsNew')
					},
					{
						name : "integrations" ,
						path : "integrations" ,
						component : cmp('portal/teacher/Integrations') ,
					},
					{
						path : "integrations/:integration" ,
						component : cmp('portal/teacher/integrations/Main') ,
						children : [
							{
								name : "integrations-classroom" ,
								path : "classroom-landing" ,
								component : cmp('portal/teacher/integrations/Classroom')
							}
						]
					},
					// Profile
					{
						name : "settings" ,
						path : "settings" ,
						component : cmp('portal/teacher/settings/Settings') ,
						redirect : "/portal/teacher/settings/profile" ,
						children : [
							{
								name : "settings-profile" ,
								path : "profile" ,
								meta : { section : "profile" },
								props : true ,
								component : cmp('portal/common/Profile')
							},
							{
								name : "settings-tags" ,
								path : "user-tags" ,
								meta : { section : "my tags" },
								component : cmp('portal/teacher/organization/AdminTags' ) ,
							},
							{
								name : "settings-tags-detail" ,
								path : "user-tags/:tagid" ,
								meta : { section : "my tags" },
								props : true ,
								component : cmp('portal/teacher/organization/AdminTag' ) ,
							},
							{
								name : "settings-color-guide" ,
								path : "color-guide" ,
								meta : { section : "color guide" },
								props : true ,
								component : cmp('portal/teacher/settings/ColorGuide')
							},
							{
								name : "settings-my-school" ,
								path : "school" ,
								meta : { section : "school" },
								props : true ,
								component : cmp('portal/teacher/settings/Organization')
							}
						]
					},
					// Placeholder ORG page
					{
						path : "organizations" ,
						component : cmp('portal/teacher/Organizations') ,
					},
					// Organization
					{
						path : "organization" ,
						component : cmp('portal/teacher/organization/Home') ,
						redirect : "/portal/teacher/organization/members" ,
						children : [
							{
								path : "members" ,
								meta : { section : "members" } ,
								component : cmp('portal/teacher/organization/Members' ) ,
								name : 'org-members'
							},
							{
								path : "reports" ,
								component : cmp('portal/teacher/organization/reports/Reports' ) ,
								name : 'org-reports',
								children : [
									{
										path : "factors" ,
										meta : { section : "factors report" } ,
										component : cmp('portal/teacher/organization/reports/Factors' ) ,
										name : 'org-reports-factors'
									},
									{
										path : "mood" ,
										meta : { section : "mood report" } ,
										component : cmp('portal/teacher/organization/reports/Mood' ) ,
										name : 'org-reports-mood'
									},
									{
										path : "students" ,
										meta : { section : "students report" } ,
										component : cmp('portal/teacher/organization/reports/Students' ) ,
										name : 'org-reports-students'
									},
								]
							},
							{
								path : "billing" ,
								meta : { section : "billing" } ,
								component : cmp('portal/teacher/organization/billing/Billing' ) ,
								name : 'org-billing' ,
								redirect : "/portal/teacher/organization/billing/summary" ,
								children : [
									{
										path : "summary" ,
										meta : { section : "billing" } ,
										component : cmp('portal/teacher/organization/billing/Home' ) ,
										name : 'org-billing-summary'
									},
									{
										path : "subscription" ,
										meta : { section : "billing" } ,
										component : cmp('portal/teacher/organization/billing/Subscription' ) ,
										name : 'org-billing-subscription'
									},
									{
										path : "checkout" ,
										meta : { section : "billing" } ,
										component : cmp('portal/teacher/organization/billing/Checkout' ) ,
										name : 'org-billing-checkout'
									},
									{
										path : "confirmation/:orderid" ,
										meta : { section : "billing" } ,
										component : cmp('portal/teacher/organization/billing/Confirmation' ) ,
										name : 'org-billing-confirmation'
									},
									{
										path : "orders" ,
										meta : { section : "billing" } ,
										component : cmp('portal/teacher/organization/billing/Orders' ) ,
										name : 'org-billing-orders'
									},
									{
										path : "orders/:orderid" ,
										meta : { section : "billing" } ,
										component : cmp('portal/teacher/organization/billing/OrderDetail' ) ,
										name : 'org-billing-orderdetail'
									},
								]
							},
							{
								path : "admin-tags" ,
								meta : { section : "tags" } ,
								component : cmp('portal/teacher/organization/AdminTags' ) ,
								name : 'org-admin-tags' ,
							},
							{
								path : "admin-tags/:tagid" ,
								meta : { section : "tags" } ,
								name : 'org-admin-tags-edit' ,
								component :  cmp('portal/teacher/organization/AdminTag' ) ,
							},
							{
								path : "wordlists" ,
								meta : { section : "feelings banks" } ,
								component : cmp('portal/teacher/organization/WordLists' ) ,
								name : 'org-wordlists'
							},
							{
								path : "wordlists/:wordlistid" ,
								meta : { section : "feelings banks" } ,
								component : cmp('portal/teacher/organization/WordList' ) ,
								name : 'org-wordlist'
							},
							{
								path : "factors" ,
								meta : { section : "factors" } ,
								component : cmp('portal/teacher/organization/factors/Factors' ) ,
								name : 'org-factors' ,
								redirect : "/portal/teacher/organization/factors/current" ,
								children : [
									{
										path : "current" ,
										meta : { section : "factors" } ,
										component : cmp('portal/teacher/organization/factors/Current' ) ,
										name : 'org-factors-current' ,
									},
									{
										path : "inactive" ,
										meta : { section : "factors" } ,
										component : cmp('portal/teacher/organization/factors/Inactive' ) ,
										name : 'org-factors-inactive' ,
									},
								]
							},
							{
								path : "trusted-adults" ,
								meta : { section : "trusted adults" } ,
								component : cmp('portal/teacher/organization/TrustedAdults' ) ,
								name : 'org-trusted-adults'
							},
						]
					},
					// Notifications
					{
						name : "notifications" ,
						path : "notifications" ,
						component : cmp('portal/teacher/Notifications') ,
					},
					// Notifications
					{
						name : "notes" ,
						path : "notes" ,
						component : cmp('portal/teacher/Notes') ,
					},
					// admin
					{
						name : "admin" ,
						path : "admin" ,
						redirect : "/portal/teacher/admin/users" ,
						component : cmp( 'portal/teacher/admin/Main' ),
						children : [
							{
								name : "adminUsers" ,
								path : "users" ,
								component : cmp( 'portal/teacher/admin/Users' ),
							},
							{
								name : "teacherContacts" ,
								path : "users/:domain" ,
								component : cmp( 'portal/teacher/admin/UsersDomain' ),
							},
							{
								name : "newContacts" ,
								path : "newusers" ,
								component : cmp( 'portal/teacher/admin/UsersDomain' ),
							},
							{
								name : "checkins" ,
								path : "checkins" ,
								component : cmp( 'portal/teacher/admin/Checkins' ),
							},
							{
								name : "questions" ,
								path : "questions" ,
								component : cmp( 'portal/teacher/admin/Questions' ),
							},
						]
					}
				]
			},
			// student portal
			{ 
				path : "student" ,
				component :  cmp('portal/student/Main') ,
				children : [
					// Daily survey
					{
						name : "student-survey" ,
						path : "" ,
						component : cmp('portal/student/Survey')
					},
					{
						name : "student-settings" ,
						path : "profile" ,
						component : cmp('portal/common/Profile') ,
						/*children : [
							{
								name : "student-settings-profile" ,
								path : "settings" ,
								component : cmp('portal/common/Profile')
							},
							{
								name : "student-settings-history" ,
								path : "history" ,
								component : cmp('portal/student/History')
							}
						]*/
					}
					
				]
			},
		]
	},
]

const router = createRouter({
	history: createWebHistory() ,
	routes ,
	scrollBehavior() {
		document.querySelector('.portal-content')?.scrollTo( 0 , 0 );
	},
});

export default router;