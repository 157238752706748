export default {

	namespaced : true ,
	state : {
		preferences : {}
	},
	mutations : {
		setPreference: ( state , { key , value , persistent = false } ) => {
			state.preferences[ key ] = value;
			// Also cache the key
			window.localStorage.setItem( `${ persistent ? 'persistent' : 'preferences' }.${key}` , value );
		},
		clearPreferences: ( state ) => {
			state.preferences = {};
		}
	},
	getters : {
		preference : ( state ) => ( key ) => {
			let value = state.preferences[ key ] || undefined ;
			// If it's not in the store...
			if( typeof value == 'undefined' ){
				
				value = window.localStorage.getItem(`preferences.${key}`) || window.localStorage.getItem(`persistent.${key}`);
				
				// Move this to vuex if we got it out of the store
				if( typeof value != 'undefined' ){
					value = value == "false" ? false : value == "true" ? true : value ;
					state.key = value;
				}
			}

			return value;

		}
	}

}