export default {

	namespaced : true ,
	state : {
		mobileMenuActive : false
	},
	mutations : {
		toggleMenu( state ){
			state.mobileMenuActive = !state.mobileMenuActive;
		},
		closeMenu( state ){
			state.mobileMenuActive = false;
		}
	}

}